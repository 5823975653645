// src/redux/reducers.js
import { SET_TOAST_DATA } from './action';

const initialState = {
    toastData: {
        visible: false,
        header: '',
        message: '',
        isSuccess: false,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOAST_DATA:
            return {
                ...state,
                toastData: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;