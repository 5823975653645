
import { createStore, combineReducers } from 'redux';
import reducer from './reducer';

const rootReducer = combineReducers({
  // Add your other reducers here
  toastData: reducer,
});

const store = createStore(rootReducer);

export default store;