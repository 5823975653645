// import React, { Component, Suspense } from 'react'
// import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
// import './scss/style.scss'

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

// // Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// import './global.css'
// // Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
// const ForgotPassword = React.lazy(() => import('./views/pages/forgot-pass/forgotPass'))

// class App extends Component {
//   checkAuthentication = () => {
//     return localStorage.getItem('user') ? true : false

//   }

//   // componentDidMount(){

//   // }

//   render() {
//     return (
//       <HashRouter>
//         <Suspense fallback={loading}>
//           <Routes>
//             <Route
//               path="*"
//               element={this.checkAuthentication() ? <DefaultLayout /> : <Navigate to="/login" />}
//             />
//             <Route exact path="/login" name="Login Page" element={<Login />} />
//             <Route exact path="/register" name="Register Page" element={<Register />} />
//             <Route exact path="*" name="Page 404" element={<Page404 />} />
//             <Route exact path="/500" name="Page 500" element={<Page500 />} />
//             <Route exact path="/forgot-password/:id" name="ForgotPassword" element={<ForgotPassword />} />

//           </Routes>
//           <Routes>
//           </Routes>
//         </Suspense>
//       </HashRouter>
//     )
//   }
// }

// export default App


import React, { Component, Suspense } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import './global.css';
import { baseUrl, jwt } from './utils/helper';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgot-pass/forgotPass'));

class App extends Component {
  state = {
    isAuthenticated: false,
    loading: true,
  };

  componentDidMount() {
    this.checkAuthentication();
  }

  checkAuthentication = async () => {
    // Adjust this based on where you store your token
    if (jwt) {
      try {
        const response = await fetch(`${baseUrl}/admin/check-token`, { // Adjust this based on your API endpoint

          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: jwt }),
        });
        const data = await response.json();
        if (data.success) {
          this.setState({ isAuthenticated: true, loading: false });
        } else {

          this.setState({ isAuthenticated: false, loading: false });
        }
      } catch (error) {
        console.error('Error checking token:', error.message);
        this.setState({ isAuthenticated: false, loading: false });
      }
    } else {
      this.setState({ isAuthenticated: false, loading: false });
    }
  };

  render() {
    const { isAuthenticated, loading } = this.state;

    if (loading) {
      return loading; // Show loading spinner while checking authentication
    }

    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              path="*"
              element={isAuthenticated ? <DefaultLayout /> : <Navigate to="/login" />}
            />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="*" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/forgot-password/:id" name="ForgotPassword" element={<ForgotPassword />} />
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
